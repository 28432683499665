













































































import {MultipleItem} from "@/handlers/interfaces/ContentUI"
import {Component, Mixins, PropSync, Ref, Watch} from "vue-property-decorator"
import AddressService from "@/services/AddressService"
import ClientService from "@/services/ClientService"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import Rules from "@/services/tool/Rules"
import Handler from "@/handlers/Handler"
import Address from "@/models/Address"
import Client from "@/models/Client"
import Product from "@/models/Product";
import SessionModule from "@/store/SessionModule";
import OrderDetail from "@/models/OrderDetail";
import ClientDialog from "@/components/dialog/ClientDialog.vue";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";
import SessionMixin from "@/mixins/SessionMixin";

@Component({components: {ClientDialog, CreateAddressDialog}})
export default class OrderDataTab extends Mixins(SessionMixin) {
    @Ref() readonly form!: HTMLFormElement

    validateForm() {
        return this.form.validate()
    }

    get rules() {
        return Rules
    }

    @PropSync('address') addressModel!: Nullable<Address>
    @PropSync('client') clientModel!: Nullable<Client>
    @PropSync('reference') syncedReference!: Nullable<string>
    @PropSync('description') syncedDescription!: Nullable<string>

    lang: any = getModule(LangModule).lang
    pvp: boolean = false
    sessionModule: SessionModule = getModule(SessionModule)
    orderDetails: OrderDetail[] | undefined = []
    clientDialog: boolean = false
    addressDialog: boolean = false
    addresses: MultipleItem<Address> = {items: [], totalItems: 0}
    clients: MultipleItem<Client> = {items: [], totalItems: 0}
    loading: boolean = false

    clientSearch: string = ""
    addressSearch: string = ""

    created() {
        this.refresh()
    }

    async refresh() {
        await this.getClients()
        if (this.clientModel?.id != null) {
            await this.getAddresses()
        }
    }

    async getClients() {
        if (this.isCommercial) {
            await Handler.getItems(this, this.clients, () => ClientService.getMyOwnClients(0, 10, this.clientSearch))
        } else if (this.isDistributor || this.isCompanyManager) {
            await Handler.getItems(this, this.clients, () =>
                ClientService.getClientsUltimate(0, 10, this.clientSearch, null, null)
            )
        }
    }

    @Watch('clientModel', {deep: true})
    async getAddresses() {
        if (this.clientModel?.id) {
            try {
                await Handler.getItems(this, this.addresses, () => AddressService.getAddressesByClient(this.clientModel?.id!!))
            } catch (e) {
                console.log(e)
            }
        }
    }

}


