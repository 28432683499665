



































































import {Component, Mixins, Ref} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import Order, {OrderType} from "@/models/Order";
import Handler from "@/handlers/Handler";
import User from "@/models/User";
import Client from "@/models/Client";
import OrderDataTab from "@/components/tabs/order/OrderDataTab.vue";
import OrderAddProductsTab from "@/components/tabs/order/OrderAddProductsTab.vue";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import OrderDetail from "@/models/OrderDetail";
import Product from "@/models/Product";
import OrderDetailListComponent from "@/components/OrderDetailListComponent.vue";
import SnackbarModule from "@/store/SnackbarModule";
import OrderService from "@/services/OrderService";
import SessionModule from "@/store/SessionModule";
import SessionMixin from "@/mixins/SessionMixin";

@Component({components: {OrderDataTab, OrderAddProductsTab, OrderDetailListComponent}})
export default class NewOrder extends Mixins(SessionMixin) {
    @Ref() readonly form!: HTMLFormElement

    componentKey = 0
    sessionModule: SessionModule = getModule(SessionModule)
    lang: any = getModule(LangModule).lang
    order: SingleItem<Order> = {item: new Order()}
    isValid: boolean = false
    commercials: MultipleItem<User> = {items: [], totalItems: 0}
    clients: MultipleItem<Client> = {items: [], totalItems: 0}
    loading: boolean = false
    pvp: boolean = false

    tab: number = 0
    tabs: string[] = ["/products", "/drafts"]

    get isEdit() {
        return this.$route.name == "Editar un pedido" && this.isDraft
    }

    get isWaiting() {
        return this.order.item.orderType == OrderType.WAITING
    }

    get isDraft() {
        return ((this.order.item.orderType == OrderType.DRAFT) || this.order.item.orderType === undefined)
    }

    get orderIsEmpty(): boolean {
        return this.order.item.orderDetails?.length == 0
    }

    validateOrder() {
        // @ts-ignore
        return this.$refs.orderDataTab.validateForm()
    }

    created() {
        if (this.$route.params.orderId) {
            this.getOrder()
        }
        this.refresh()
    }

    async getOrder() {
        try {
            await Handler.getItem(this, this.order, () => OrderService.getOrder2(Number(this.$route.params.orderId)))
        } catch (e) {
            console.log(e)
        }
    }

    forceUpdate() {
        this.componentKey++
    }

    refresh() {
    }

    onQuantityChanged(orderDetail: OrderDetail) {
        let index = this.order.item.orderDetails?.indexOf(orderDetail)
        this.order.item.orderDetails![index!].quantity = orderDetail.quantity
    }

    removeOrderDetail(orderDetail: OrderDetail) {
        getModule(DialogModule).showDialog(new Dialog(
            this.lang.warning, this.lang.removeProductFromOrderConfirmation, () => {
                this.order.item.orderDetails = this.order.item.orderDetails?.filter(od => od.product!!.id != orderDetail.product!!.id)
            }
        ))
    }

    addOrderDetail(product: Product) {
        //Si el producto ya ha sido añadido anteriormente, isAlreadyAdded sería -1.
        let isAlreadyAdded = this.order.item.orderDetails?.findIndex(od => od.product?.id == product.id)

        if (isAlreadyAdded != -1) {
            getModule(DialogModule).showDialog(new Dialog(
                this.lang.warning, this.lang.alreadyAddedProductMessage,
                async () => {
                    if (product.quantity == 0) {
                        this.order.item.orderDetails![isAlreadyAdded!].quantity = 1
                        product.quantity = 1
                    } else {
                        this.order.item.orderDetails![isAlreadyAdded!].quantity = product.quantity
                    }
                }
            ))
        } else {
            const orderDetail = new OrderDetail()
            orderDetail.product = product
            if (product.quantity == 0) {
                orderDetail.quantity = 1
                product.quantity = 1
            } else {
                orderDetail.quantity = product.quantity
            }

            this.order.item.orderDetails?.push(orderDetail)
        }
    }

    removeNullOrderDetails() {
        this.order.item.orderDetails = this.order.item.orderDetails?.filter(orderDetail => orderDetail.quantity != 0)
    }

    async createOrderConfirmation() {
        this.removeNullOrderDetails()
        if (this.validateOrder()) {
            if (this.orderIsEmpty) {
                getModule(SnackbarModule).makeToast(this.lang.addProductToContinue)
            } else {
                getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.placeOrderQuestion,
                    async () => {
                        await this.draft()
                        await this.$router.push("/my-orders")
                    }
                ))
            }
        }
    }

    async saveAnOrder() {
        this.removeNullOrderDetails()
        if (this.validateOrder()) {
            if (this.orderIsEmpty) {
                getModule(SnackbarModule).makeToast(this.lang.addProductToContinue)
            } else {
                getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.saveOrderQuestion,
                    async () => {
                        await this.patchOrder()
                        await this.$router.push("/my-orders")
                    }
                ))
            }
        }
    }

    async patchOrder() {
        await Handler.getItem(this, this.order, () => OrderService.patchOrder2(this.order.item.id!!, this.order.item))
    }

    async draft() {     //Use this to create orders
        await Handler.getItem(this, this.order, () => OrderService.draftOrder2(this.order.item))

    }

    async sendOrder() {
        if (this.order.item.id == null) {
            await this.draft()
        }
        await this.patchOrder()
        await Handler.getItem(this, this.order, () => OrderService.sendOrder2(this.order.item.id!))
    }

    async sendOrderConfirmation() {
        if (this.validateOrder()) {
            if (this.orderIsEmpty) {
                getModule(SnackbarModule).makeToast(this.lang.addProductToContinue)
            } else {
                getModule(DialogModule).showDialog(new Dialog(
                    this.lang.warning, this.$route.params.orderId ? this.lang.sendOrderToCentralQuestion : this.lang.newlyOrderSendWarning,
                    async () => {
                        await this.sendOrder()
                        await this.$router.push("/my-orders")
                    }))
            }
        }
    }

    async placeAnOrderConfirmation() {
        this.removeNullOrderDetails()
        if (this.validateOrder()) {
            if (this.orderIsEmpty) {
                getModule(SnackbarModule).makeToast(this.lang.addProductToContinue)
            } else {
                getModule(DialogModule).showDialog(new Dialog(
                    this.lang.warning,
                    this.$route.params.orderId ? this.lang.saveOrderQuestion : this.lang.newlyOrderSendWarning,
                    async () => {
                        await this.patchOrder()
                        await this.$router.push("/my-orders")
                    }
                ))
            }
        }
    }

    get getTotalPrice() {
        let total: number = 0
        this.order.item.orderDetails!!.forEach(orderDetail => {
            if (this.pvp) {
                total += (Number(orderDetail.product!.commercialRate) * Number(orderDetail.quantity))
            } else {
                total += (Number(orderDetail.product!.rates![this.orderRateId - 1].price) * Number(orderDetail.quantity))
            }

        })
        return total.toFixed(2)
    }

    isExistingOrder() {
        return this.$route.params.orderId != null
    }

    get orderRateId() {
        return this.isExistingOrder() ? this.order.item.creationRate!! : this.sessionModule.session.user.rateId!!
    }

}
